import { Text, Avatar, Button, Group, Menu, UnstyledButton, rem, ActionIcon, Box, Stack } from "@mantine/core";
import { navigate } from "gatsby";
import React from "react";
import { Role, useAuthContext, useCheckRoles } from "../../context/AuthProvider";
import { IconUser, IconSettings, IconLogout, IconLogin, IconLogin2, IconArrowsLeftRight, IconMessageCircle, IconPhoto, IconSearch, IconTrash, IconUniverse, IconUsers } from "@tabler/icons-react";

export function Profile() {
    const { userInfo, signout } = useAuthContext();
    const right = useCheckRoles([Role.Admin])

    if (userInfo) {
        let name = ''
        if (userInfo.firstName || userInfo.lastName)
            name = (userInfo.firstName ?? '') + ' ' + (userInfo.lastName ?? '')
        else name = userInfo.email ?? userInfo.username ?? ''
        return <>
            <Menu width={200} withArrow radius={'md'}>
                <Menu.Target>
                    <Group>
                        <Stack gap={0} visibleFrom="sm">
                            <Text>{name}</Text>
                            {right && <Text size="xs" ml={'auto'}>Admin</Text>}
                        </Stack>
                        <ActionIcon
                            // onClick={() => navigate('/profile')}
                            variant="light"
                            size={'lg'}
                            radius={'xl'}
                        >
                            <Avatar>{name.substring(0, 1).toUpperCase()}</Avatar>
                        </ActionIcon>
                    </Group>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Item
                        fz={'md'}
                        leftSection={<IconUser style={{ width: rem(14), height: rem(14) }} />}
                        onClick={() => navigate('/profile')}
                    >
                        Profile
                    </Menu.Item>
                    <Menu.Divider />
                    {right && <>
                        <Menu.Item fz={'md'} onClick={() => navigate('/manage/games/?type=bigbang')} leftSection={<IconUniverse style={{ width: rem(14), height: rem(14) }} />}>
                            Big Bang
                        </Menu.Item>
                        <Menu.Item fz={'md'} onClick={() => navigate('/manage/users')} leftSection={<IconUsers style={{ width: rem(14), height: rem(14) }} />}>
                            Users
                        </Menu.Item>
                        <Menu.Divider />
                    </>}

                    <Menu.Item
                        fz={'md'}
                        color="red"
                        leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />}
                        onClick={signout}
                    >
                        Sign out
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </>
    }

    const login = () => {
        navigate('/signin')
    }


    return <>
        <Button visibleFrom="md" variant="light" color="gray" radius={'sm'} size="sm" fz={'sm'} onClick={login}>
            Đăng nhập
        </Button>
        <ActionIcon hiddenFrom="md" variant="light"
            radius={'sm'} p={5}
            size={'lg'}
            onClick={login}>
            <IconLogin2 />
        </ActionIcon>
    </>
}