import "@mantine/core/styles.css";
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import React from "react";
import { MantineProvider } from "@mantine/core";
import { Notifications } from '@mantine/notifications';
import { theme } from "./src/theme";
import { Layout } from "./src/components/Layout"
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./src/context/AuthProvider"
import { CookiesProvider } from "react-cookie";
import { LanguageProvider } from "./src/components"

export const wrapPageElement = ({ element, props }) => {
  const queryClient = new QueryClient();

  return <CookiesProvider defaultSetOptions={{ path: '/' }}>
    <MantineProvider theme={theme} defaultColorScheme="dark">
      <Notifications position='bottom-center' />
      <QueryClientProvider client={queryClient}>
        <LanguageProvider>
          <AuthProvider>
            <Layout {...props}>
              <>{element}</>
            </Layout>
          </AuthProvider>
        </LanguageProvider>
      </QueryClientProvider>
    </MantineProvider>
  </CookiesProvider>;
};
