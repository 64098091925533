import axios from "axios";
import { navigate } from "gatsby";
// @ts-ignore
import NProgress from "nprogress"
import { notifications } from "@mantine/notifications"
// @ts-ignore
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { useQuery } from "react-query";

function getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function deleteCookie(name: string) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

var requests = 0;

export const axiosInstance = axios.create({
    baseURL: process.env.GATSBY_API_URL,
    //timeout: 1000,
    headers: {
        //'X-Custom-Header': 'foobar' 
    },
});

axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    const cookie = getCookie('user')
    if (cookie) {
        const json = JSON.parse(cookie)
        if (!json?.access_token) {
            const decoded = jwtDecode(json?.access_token);
            if (decoded && dayjs(decoded.exp).diff(dayjs()) < 0) {
                navigate('/signin')
                return Promise.reject('Unauthorized');
            }
        }
        config.headers["Authorization"] = `Bearer ${json?.access_token}`;
    }

    //console.log(config)
    if (requests === 0) {
        NProgress.start();
    }
    requests++;

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(res => {
    requests--;
    if (requests === 0) {
        NProgress.done();
    }
    return res;
}, error => {
    handleError(error)
    //return error;
    NProgress.done();
})

axios.interceptors.response.use(res => res, error => {
    handleError(error)
    //return error;
})

let lastMessage = "";
function handleError(error: any) {
    let message = "";

    if (error?.response?.status === 401) {
        deleteCookie('user')
        window.location.href = '/signin'
    }

    console.log(window.location.pathname)
    if (window.location.pathname === '/signin/' && error?.code === "ERR_NETWORK") {
        message = "Wrong username or password"
    } else {
        if (error.response) message = error.response?.data?.title || error.response?.data?.message;
        else if (error.request) message = error.message;
        else message = error.message;

        if (message === lastMessage) return;
        if (message == null || message == "") {
            message = "An error occurred while processing data! 🤥";
        }
    }

    const notification = {
        //color: 'red',
        //color: 'white',
        bg: 'orange',
        styles: {
            title: { color: 'white' },
            description: { color: 'white' },
            closeButton: { color: 'white' }
        },
        title: 'Some thing went wrong!',
        message,
    }
    // notifications.show(notification)

    PubSub.publish('NOTIFICATION', notification)

    lastMessage = "";
}

export function removeEmptyField(obj: any) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null && v != undefined)) as any;
}

export const FakeResponse = { data: null, isSuccess: false, status: '', refetch: () => { } }

export async function axiosMeduverseGet(path: string, filter: Object) {
    const params = new URLSearchParams(removeEmptyField(filter)).toString()
    const response = await axiosInstance.get(`/manage/localization/getresourcestring?${params}`);
    //console.log(response);
    return response;
}

export function useCommonQuery(path: string, filter: Object) {
    async function getData() {
        const params = new URLSearchParams(removeEmptyField(filter)).toString()
        const response = await axiosInstance.get(`${path}?${params}`);
        //console.log(response);
        return response;
    }

    return useQuery([path, filter], getData, { refetchOnWindowFocus: false })
}