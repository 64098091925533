import { useQuery } from "react-query";
import { axiosInstance } from "../../axiosConfigs";

export async function getProfile() {
    const response = await axiosInstance.get(`/user/getprofile`);
    return response;
}

export function useProfile() {

    const { data, isSuccess } = useQuery(['profile'], getProfile, { refetchOnWindowFocus: false })
    if (!isSuccess || !data || !data.data) return []

    return data.data
}
